<template>
  <b-container class="pos-fix">
    <b-row>
      <b-col cols="12 my-3">
        <p class="text-center font-size-25">{{ title }}</p>
        <p class="color-grey text-center">{{ description }}</p>
      </b-col>
      <b-col cols="12">
        <b-col>
          <InputText
            v-model="form.Telephone"
            textFloat="เบอร์โทรศัพท์"
            placeholder="เบอร์โทรศัพท์"
            type="number"
            name="telephone"
            size="lg"
            isRequired
            @onkeypress="isNumber($event)"
            :isValidate="isValidate"
            :v="v"
            class="f-regular"
            :oninput="`if( this.value.length > 10 )  this.value = this.value.slice(0,10)`"
            inputmode="numeric"
          />
        </b-col>
      </b-col>
    </b-row>
    <div class="register-bottom fixed-bottom bg-white p-2 shadow">
      <b-button
        class="w-100 btn-register"
        type="button"
        @click="$emit('ValidateTelephone')"
        :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
        >ยืนยัน</b-button
      >
    </div>
  </b-container>
</template>

<script>
import InputText from "@/components/input/InputText";
export default {
  props: {
    title: {
      required: false,
      type: String
    },
    description: {
      required: false,
      type: String
    },
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    isValidate: {
      required: true,
      type: Boolean
    }
  },
  components: {
    InputText
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pos-fix {
  position: fixed;
}
</style>