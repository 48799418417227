<template>
  <div class="background-validate content-page">
    <InputTelephone 
      title="เข้าสู่ระบบ"
      description="หากคุณเคยลงทะเบียนผ่านสาขาของ Hourglass Thailand อยู่แล้ว สามารถระบุเบอร์โทรศัพท์เพื่อเชื่อมต่อข้อมูลได้เลยค่ะ"
      :form="form"
      @ValidateTelephone="ValidateTelephone"
      :isValidate="$v.form.Telephone.$error"
      :v="$v.form.Telephone"
    />
    <ModalError ref="modalError" :text="successMessage" />
  </div>
</template>

<script>
import InputTelephone from "@/components/input/InputTelephone";
import ModalError from "@/components/alert-modal/ModalError";
import { required , minLength } from "vuelidate/lib/validators";
export default {
  components: {
    InputTelephone,
    ModalError
  },
  data() {
    return {
      form: {
        Telephone: "",
        SOCIALPROVIDER: "LINE",
        SOCIALID: ""
      },
      successMessage : ""
    };
  },
  validations() {
    return {
      form: {
        Telephone: { required , minLength: minLength(10) }
      }
    };
  },
  methods: {
    async ValidateTelephone() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.form.SOCIALID = this.$cookies.get("user_profile_token").userId;
      await this.$axios
      .post(`${process.env.VUE_APP_API}/api/v1/user/ValidateTelephone`, this.form)
      .then(async data => {
        if(data.result == 1){
          this.$router.push({ path: 'confirmotp', query: { tel: this.form.Telephone } });
        }else{
          this.successMessage = data.message;
          this.$refs.modalError.show();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.background-validate{
  background-color: #fff;
  position: fixed;
}
.content-page{
  margin-top: 80px;
}
</style>
